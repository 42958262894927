* 
{

}

html, body 
{
	width: 100%;
	height: 100%;
}

html, body, p, header, div, span, ul 
{
	margin: 0;
	padding: 0;
}

body 
{
	font-family: LeagueGothic, sans-serif;
	background-image: url("../img/Background.png");
	background-repeat: no-repeat;
	background-attachment: fixed;
	background-position: center;
	background-size: cover;
	background-color: #2f0b4a;
	color: #fff;
}

a:visited 
{
	color: #fff;
}

a:link 
{
	text-decoration:none;
	color: #fff;
}

a:link:hover 
{
	text-decoration:underline;
}

.classic-page 
{
	display: flex;
	flex-direction: column;
}

.parent 
{
	margin: 0;
	padding: 0;
	width: 100%;
	height: 100%;
	display: flex;
}

.parent .container 
{
	width: 100%;
}

.child 
{
	position: fixed;
	left: 0px; /* compatibility */
	height: 40px;
	width: 100%;
	/* background: #f00; */
	align-self: flex-end;
}

h1 
{
	font-size: 3em;
	font-weight: normal;
}

.content 
{
	padding: 15px;
}

.noselect 
{
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.App
{
	visibility: hidden;
}