header, .navigation 
{
	height: 30px;
}

.navigation 
{
	position: fixed;
	padding: 15px;
	margin:-5px 0px 0px -2px;
	width: 100%;
	font-size: 1.5em;
	z-index: 200;
}

.dark-navigation
{
	background-color: #232323;
}

.navigation .logo 
{
	/*border: 3px solid green;*/
	/*display: inline;*/
	position: fixed;
	left: 0px;
	width: 100%;
	text-align: center;
	background-image: url("../img/AURLogo.svg");
	background-repeat: no-repeat;
	background-position: center;
	background-size: 128px 30px;
	z-index: 0;
}

.navigation .logo-fullscreen
{
	filter: drop-shadow(4px 4px 3px rgba(0, 0, 0, 0.6));
}

.navigation .logo .text 
{
	font-family: AmpUpRadio;
	visibility: hidden;
}

.navigation ul 
{
	display: inline-block;
	visibility: hidden;
	list-style-type: none;
	cursor:default;
}

.navigation ul li 
{
	display: inline-block;
	margin:5px 10px 5px 2px;
}

.navigation ul li a 
{
	display:block;
}

.navigation .hamburger 
{
	position: relative;
	/*display: inline;*/
	cursor: pointer;
	z-index: 1;
}