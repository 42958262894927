.fullscreenplayer
{
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	/*background: linear-gradient(#422ad5, #000);*/
	/*background-image:url("../img/FS-AUR.png"),linear-gradient(#422ad5, #000);
	background-size:cover;
	background-position:center;*/
	z-index: 100;
}

.fullscreenplayer-hidden
{
	display: none;
}

.fullscreenplayer .bg
{
	position: fixed;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	/*background: linear-gradient(#422ad5, #000);*/
	background-image:url("../img/FS-AUR.png"),linear-gradient(#422ad5, #000);
	background-size:cover;
	background-position:center;
	z-index: -1;
}

.fullscreenplayer .inner-player
{
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	text-align: center;
}

.fullscreenplayer .controls
{
	/*margin-bottom: 20vh;*/
	position: relative;
	left: 10%;
	width: 80%;
	height: 8px;
	background-color: #eaeaea;
	border-radius: 3px;
	opacity: 0.4;
}

.fullscreenplayer .button-controls
{
	margin-bottom: 10vh;
	position: relative;
	left: 20%;
	width: 60%;
	font-size: 5.0vh;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.fullscreenplayer .button-controls .playlist
{
}

.fullscreenplayer .button-controls .volume
{
}

.fullscreenplayer .artwork 
{
	margin-top: 60px;
	background-image: url("../img/image_21.png");
	background-repeat: no-repeat;
	background-size: 100% 100%;
	background-position: center;
	display: inline-block;
    position: relative;
    max-width: 300px;
    max-height: 300px;
    top: 0px;
    /*left: 50%;
    transform: translate(-50%, 0%);*/
	width: 60vw;
	height: 60vw;
}

.fullscreenplayer .infos
{
	padding-top: 15px;
	text-align: center;
	font-size: 4.0vh;
	text-transform: uppercase;
	/*white-space: nowrap;*/
	overflow: hidden;
	text-overflow: ellipsis;
}

.fullscreenplayer .infos .album 
{
	/*color: #9e92e9;*/
	opacity: 0.6;
}

/* play button stuff */

.fullscreenplayer .button 
{
	background-color: #fff;
	/*background-image: url("../img/AURPlayButton.svg");*/
	background-repeat: no-repeat;
	background-size: cover;
	cursor: pointer;
	border-radius: 50%;
	display: flex;
	align-items: center;
	font-size: 1.5em;
	margin-top: -10px;
}

.fullscreenplayer .button span
{
	width: 100%;
	height: 100%;
	color: #8f00df;
	margin-top: 3px;
	margin-left: 2px;
	filter: drop-shadow(2px 2px 7px rgba(0, 0, 0, 0.25));
}
