@font-face 
{
	font-family: LeagueGothic;
	src: url(../fonts/LeagueGothic-Regular.woff) format("woff"),
	url(../fonts/LeagueGothic-Regular.otf) format("opentype");
	font-weight: normal;
	font-style: normal;
}

@font-face 
{
	font-family: LeagueGothic;
	src: url(../fonts/LeagueGothic-Italic.woff) format("woff"),
	url(../fonts/LeagueGothic-Italic.otf) format("opentype");
	font-style: italic;
}

@font-face 
{
	font-family: AmpUpRadio;
	src: url(../fonts/LeagueGothic-Regular.woff) format("woff"),
	url(../fonts/AmpUpRadioLogo-Regular.otf) format("opentype");
	font-weight: normal;
	font-style: normal;
}
