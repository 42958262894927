.popoutnavigation 
{
	position: absolute;
	top: 0px;
	left: 0px;
	width: 250px;
	bottom: 85px;
	font-size: 2em;
	opacity: 1;
	background-color: #0e0e0e;
	box-shadow: 4px 0px 10px rgba(0, 0, 0, 0.25);
	visibility: hidden;
	z-index: 1000;
	padding: 10px;
	padding-left: 16px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.popoutnavigation-fullscreen
{
	bottom: 0px;
}

.popoutnavigation .inner-frame 
{
	/*margin: 10px;
	margin-left: 16px;*/
}

.popoutnavigation .bottom-frame
{
	/*margin: 10px;
	margin-left: 16px;*/
	/*padding-bottom: 16px;*/
	/*position: fixed;
	bottom: 0px;
	width: 100%;*/
}

.popoutnavigation .bottom-frame span
{
    padding-right: 20px;
}

.close-popoutnavigation 
{
    margin-left:-6px;
	cursor: pointer;
}

.popoutnavigation ul 
{
	list-style-type: none;
	text-transform: uppercase;
}

.popoutnavigation li
{
	padding-top: 5px;
	padding-bottom: 5px;
}

.popoutnavigation li a:hover
{
    text-shadow: #fff 0 0 10px;
    text-decoration: none;
}