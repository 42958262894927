.attention 
{
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	visibility: hidden;
	background-color: #0e0e0e;
	opacity: 0.4;
	z-index: 999;
}

.alerts 
{
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	visibility: hidden;
	z-index: 1000;
}

.alert 
{
	padding: 15px;
	width: 80%;
	color: #000;
	background-color: #fff;
	opacity: 1;
	font-size: 6em;
	text-align: center;
}