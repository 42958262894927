.player .artwork 
{
	background-image: url("../img/image_21.png");
	background-repeat: no-repeat;
	background-size: cover;
	position: fixed;
	display: inline-block;
	width: 85px;
	height: 85px;
}

.player .buttonbg 
{
	background-color: #422ad5;
	position: fixed;
	display: inline-block;
	/*margin-left: 55px;
	margin-top: 15px;*/
	width: 50px;
	height: 50px;
	border-radius: 50%;
}

.player .button-container 
{
	position: relative;
	left: 60px;
	top: 20%;
}

.player .button 
{
	background-color: #fff;
	/*background-image: url("../img/AURPlayButton.svg");*/
	background-repeat: no-repeat;
	background-size: cover;
	position: fixed;
	display: inline-block;
	margin-left: 5px;
	margin-top: 5px;
	/*margin-left: 60px;
	margin-top: 20px;*/
	width: 40px;
	height: 40px;
	cursor: pointer;
	border-radius: 50%;
	display: flex;
	align-items: center;
}

.player .button span
{
	width: 100%;
	height: 100%;
	color: #8f00df;
	font-size: 2.2em;
	margin-top: 3px;
	margin-left: 2px;
	filter: drop-shadow(2px 2px 7px rgba(0, 0, 0, 0.25));
}

.player .old-playing
{
	background-image: url("../img/AURPauseButton.svg");
	background-repeat: no-repeat;
	background-size: cover;
	/* workaround cuz svg is wrong oof */
	margin-left: 1px;
	margin-top: 1px;
	width: 50px;
	height: 50px;
}

.player .infos 
{
	padding-left: 110px;
	padding-right: 15px;
	padding-top: 5px;
	padding-bottom: 5px;
	/*position: fixed;*/
	/*position: relative;
	left: 85px;*/
	width: 100%;
	height: 85px;
	/*font-size: 2.5vh;*/
	font-size: 18px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.player .infos p 
{
	text-transform: uppercase;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.player .infos .album 
{
	color: #9e92e9;
}

.player .infos .time 
{
	padding-top: 5px;
}

.player 
{
	width: 100%;
	bottom: 45px;
}

.player .innerplayer 
{
	display: flex;
	/*position: fixed;*/
	width: 100%;
	height: 85px;
	background-color: #422ad5;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	/*font-size: 1.5em;*/
	/*font-size: 2.2vh;*/
}