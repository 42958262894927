.branding 
{
	font-size: 2.5em;
	position: fixed;
	left: 40px;
	bottom: 120px;
	width: 100%;
	align-self: flex-end;
}

.branding .logo 
{
	width: 256px;
	height: 60px;
	background-image: url("../img/AURLogo.svg");
	background-repeat: no-repeat;
	background-position: center;
}

.branding .subtext 
{
	text-transform: uppercase;
}